import request from '@/utils/request';

// 获取答题练习配置 *
export const practiceConfig = params => request.post(`study/practice/config`, params);

// 答题练习音效配置 *
export const soundSave = params => request.post(`study/practice/wrongsound/save`, params);

// 获取每日答题 *
export const dailyQuestions = params => request.post(`study/practice/daily`, params);

// 获取练习详情 *
export const practiceDetail = params => request.post(`study/practice/detail`, params);

// 答题提交 *
export const practiceSubmit = params => request.post(`study/practice/submit`, params);

// 获取练习结果 *
export const practiceResult = id => request.get(`study/practice/${id}/integral`);

// 获取兴趣答题 *
export const interestLabels = () => request.get(`study/practice/interest/list`);

// 获取兴趣答题详情 *
export const interestQuestions = params => request.post(`study/practice/interest`, params);

// 获取错题数 *
export const failList = () => request.get(`study/practice/fail/list`);

// 获取错题练习 *
export const failQuestions = () => request.get(`study/practice/fail`);

// 获取错题 *
export const failDetail = params => request.post(`study/practice/fail/detail`, params);

// 获取答题记录 *
export const recordList = params => request.post(`study/practice/list`, params);

// 移除错题 *
export const failDel = id => request.get(`study/practice/${id}/del`);

// 获取模拟练习配置 *
export const simulateConfig = () => request.get(`study/practice/simulate/config`);

// 获取模拟练习题目 *
export const simulateQuestions = params => request.post(`study/practice/simulate`, params);

// 模拟练习提交 *
export const simulateSubmit = params => request.post(`study/practice/simulate/submit`, params);

// 模拟练习保存 *
export const simulateSave = params => request.post(`study/practice/simulate/save`, params);

// 练习退出 *
export const practiceEnd = (submitId, questionId) => request.get(`study/practice/${submitId}/${questionId}/end`);

// 获取练习记录 * 1每日答题 2 兴趣答题 3模拟练习 4错题练习 5自主练习
export const practiceHistory = (params) => request.get(`study/practice/${params.type}/history`);

// 获取每日一练
export const cycliclist = params => request.post(`study/practice/cyclic/list`, params)