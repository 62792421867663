<template>
  <div class="component-wrap">
    <div class="banner" id="pageTop"></div>
    <div class="header">
      <div class="crumbs">
        {{ $t("practice.current_location")
        }}<span>{{ practiceEnum[practiceType] }}</span>
      </div>
      <a-button type="primary" style="margin: -3px 0" @click="back">
        {{ $t("go_back") }}
      </a-button>
    </div>
    <a-spin :spinning="spinning">
      <div class="content-wrap">
        <div class="count-down" v-if="!disabled">
          {{ $t("project.time_left") }}：<span
            >{{ downTime.h }}:{{ downTime.m }}:{{ downTime.s }}</span
          >
        </div>
        <div
          class="question"
          :id="'que_' + item.questionId"
          v-for="(item, index) in dataInfo.questions"
          :key="item.questionId"
        >
          <div class="question-top">
            <h6>
              {{ $t("practice.question_n", [index + 1])
              }}{{ questionTypeEnum[item.questionType] }}（{{ itemScore
              }}{{ $t("practice.score") }}）
            </h6>
            <div
              class="flag"
              v-if="!disabled"
              @click="item.isMarked = !item.isMarked"
            >
              <img
                v-if="item.isMarked"
                src="@/assets/image/practice/red-flag.png"
                alt=""
              />
              <img v-else src="@/assets/image/practice/gray-flag.png" alt="" />
            </div>
          </div>
          <div class="question-body">
            <template v-if="[1, 3].includes(item.questionType)">
              <div class="question-tit">{{ item.title }}</div>
              <template v-if="item.titimgs && item.titimgs.length">
                <exam-media :mediaList="item.titimgs" />
              </template>
              <div class="question-opt">
                <a-radio-group
                  v-model:value="item.userAnswer"
                  :disabled="disabled"
                  @change="grtUserAnswer(item)"
                >
                  <a-radio
                    class="opt-radio"
                    v-model:value="opt.id"
                    v-for="(opt, optIndex) in item.options"
                    :key="opt.id"
                  >
                    <div
                      :class="[
                        'opt-item',
                        {
                          right: disabled && opt.isCorrect,
                        },
                        {
                          error: disabled && !opt.isCorrect,
                        },
                      ]"
                    >
                      <div class="letter">{{ letterOpts[optIndex] }}</div>
                      <div class="info">
                        <span class="text">{{
                          opt.title || "&nbsp;&nbsp;"
                        }}</span>
                        <exam-media
                          v-if="opt.img"
                          type="option"
                          :mediaList="[opt.img]"
                        />
                      </div>
                    </div>
                  </a-radio>
                </a-radio-group>
              </div>
            </template>
            <template v-else-if="item.questionType == 2">
              <div class="question-tit">{{ item.title }}</div>
              <template v-if="item.titimgs && item.titimgs.length">
                <exam-media :mediaList="item.titimgs" />
              </template>
              <div class="question-opt">
                <a-checkbox-group
                  v-model:value="item.userAnswer"
                  :disabled="disabled"
                  @change="grtUserAnswer(item)"
                >
                  <a-checkbox
                    class="opt-checkbox"
                    :value="opt.id"
                    v-for="(opt, optIndex) in item.options"
                    :key="opt.id"
                  >
                    <div
                      :class="[
                        'opt-item',
                        {
                          right: disabled && opt.isCorrect,
                        },
                        {
                          error: disabled && !opt.isCorrect,
                        },
                      ]"
                    >
                      <div class="letter">{{ letterOpts[optIndex] }}</div>
                      <div class="info">
                        <span class="text">{{
                          opt.title || "&nbsp;&nbsp;"
                        }}</span>
                        <exam-media
                          v-if="opt.img"
                          type="option"
                          :mediaList="[opt.img]"
                        />
                      </div>
                    </div>
                  </a-checkbox>
                </a-checkbox-group>
              </div>
            </template>
            <template v-else-if="item.questionType == 4">
              <div class="question-tit fill-in">
                <template v-for="(opt, optIndex) in item.options" :key="opt.id">
                  <span>{{ item.titles[optIndex] }}</span>
                  <a-tooltip trigger="focus" v-if="!disabled">
                    <template #title>{{ opt.title }}</template>
                    <input
                      :class="[
                        'opt-input',
                        {
                          right:
                            disabled &&
                            item.strAnswer2[optIndex] &&
                            item.strAnswer2[optIndex].indexOf(opt.title) != -1,
                        },
                        {
                          error:
                            disabled &&
                            (!(
                              item.strAnswer2[optIndex] &&
                              item.strAnswer2[optIndex].indexOf(opt.title) != -1
                            ) ||
                              opt.title == ''),
                        },
                      ]"
                      v-model="opt.title"
                      @input="grtUserAnswer(item)"
                    />
                  </a-tooltip>
                  <span
                    v-else
                    :class="[
                      'opt-input text',
                      {
                        right:
                          disabled &&
                          item.strAnswer2[optIndex] &&
                          item.strAnswer2[optIndex].indexOf(opt.title) != -1,
                      },
                      {
                        error:
                          disabled &&
                          (!(
                            item.strAnswer2[optIndex] &&
                            item.strAnswer2[optIndex].indexOf(opt.title) != -1
                          ) ||
                            opt.title == ''),
                      },
                    ]"
                    >{{ opt.title }}</span
                  >
                </template>
              </div>
              <template v-if="item.titimgs && item.titimgs.length">
                <exam-media :mediaList="item.titimgs" />
              </template>
            </template>
            <div class="explain" v-if="disabled">
              <div class="explain-answer" v-if="item.questionType == 4">
                <div class="text">
                  {{ $t("CM_GetScore") }}：{{ item.myScore }}
                </div>
                <div class="text">
                  {{ $t("Lab_Lesson_RightKey") }}：<span
                    class="right-opt"
                    v-for="(oItem, oIndex) in item.strAnswer2"
                    :key="oIndex"
                  >
                    {{ oIndex + 1 }}、<span>{{
                      oItem.replaceAll("$", " / ")
                    }}</span>
                    &nbsp;
                  </span>
                </div>
              </div>
              <div class="explain-answer" v-else>
                <div class="text">
                  {{ $t("CM_GetScore") }}：{{ item.myScore }}
                </div>
                <div class="text">
                  {{ $t("Lab_Lesson_RightKey") }}：<template
                    v-for="(opt, optIndex) in item.options"
                  >
                    <span
                      class="right-opt"
                      :key="opt.id"
                      v-if="item.strAnswer2.includes(opt.id)"
                      >{{ letterOpts[optIndex] }}</span
                    >
                  </template>
                </div>
                <div class="text">
                  {{ $t("practice.your_choice") }}：<template
                    v-for="(opt, optIndex) in item.options"
                  >
                    <span
                      class="my-opt"
                      :key="opt.id"
                      v-if="item.answers.includes(opt.id)"
                      >{{ letterOpts[optIndex] }}</span
                    >
                  </template>
                </div>
              </div>
              <p>{{ item.analysis }}</p>
            </div>
          </div>
        </div>
        <div class="question-btn" v-if="dataInfo.questions.length">
          <a-button type="primary" v-if="!disabled" @click="toSubmit()">
            {{ $t("practice.submit") }}
          </a-button>
        </div>
        <div class="answer-sheet" v-if="dataInfo.questions.length">
          <div class="btn" @click="showSheet = true">
            <div class="img">
              <img src="@/assets/image/practice/answer-sheet.png" alt="" />
            </div>
            <span>{{ $t("LB_AnswerSheet") }}</span>
          </div>
        </div>

        <a-empty
          v-if="!dataInfo.questions.length && !spinning"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("practice.no_quesition") }}</span>
          </template>
        </a-empty>

        <a-modal
          v-model:visible="showSheet"
          width="584px"
          :bodyStyle="{ padding: '40px 4px 4px 20px' }"
          title=""
          :footer="null"
        >
          <div class="sheet">
            <div class="sheet-head" v-if="!disabled">
              <h5>{{ $t("LB_AnswerSheet") }}</h5>
              <!-- 答题卡 -->
              <div class="block ed"><span></span>{{ $t("CM_Answer") }}</div>
              <!-- 已答 -->
              <div class="block no"><span></span>{{ $t("CM_NoAnswer") }}</div>
              <!-- 未答 -->
              <div class="block flag"><span></span>{{ $t("LB_Tagged") }}</div>
              <!-- 标记 -->
            </div>
            <div class="sheet-head" v-else>
              <h5>{{ $t("LB_AnswerSheet") }}</h5>
              <!-- 答题卡 -->
              <div class="block right"><span></span>{{ $t("CM_Correct") }}</div>
              <!-- 答对 -->
              <div class="block error"><span></span>{{ $t("CM_Wrong") }}</div>
              <!-- 答错 -->
              <div class="block no"><span></span>{{ $t("CM_NoAnswer") }}</div>
              <!-- 未答 -->
            </div>
            <div class="sheet-number">{{ $t("practice.question_number") }}</div>
            <div class="sheet-list">
              <div
                class="item"
                v-for="(item, queIndex) in dataInfo.questions"
                :key="item.questionId"
                :class="{
                  checked: item.isDoing,
                  marked: item.isMarked,
                  right: disabled && item.myScore == itemScore,
                  error:
                    disabled && item.answers.length && item.myScore < itemScore,
                }"
                @click="scrollTo(item.questionId)"
              >
                {{ queIndex + 1 }}
              </div>
            </div>
            <div class="sheet-btn" v-if="!disabled">
              <a-button type="primary" @click="toSubmit()">
                {{ $t("practice.submit") }}
              </a-button>
            </div>
          </div>
        </a-modal>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  createVNode,
  onBeforeUnmount,
} from "vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";

import {
  simulateQuestions,
  simulateSubmit,
  simulateSave,
  practiceDetail,
  practiceHistory,
} from "@/api/practice";

import ls from "@/utils/local-storage";
import { STORAGE_TOKEN_KEY } from "@/store/mutation-type";
import { letterOpts, questionTypeEnum } from "@/utils/business";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import examMedia from "@/views/exam/exam-media.vue";
export default {
  components: {
    examMedia,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      spinning: true,
      disabled: Number(route.query.disabled || 0) == 1,
      practiceType: Number(route.query.type || 0),
      isHistory: Number(route.query.isHistory || 0) == 1,
      isReload: false,
      submitId: Number(route.query.submitId || 0),
      practicePassScore: Number(route.query.practicePassScore || 0),
      practiceQuestions: Number(route.query.practiceQuestions || 0),
      practiceTimes: Number(route.query.practiceTimes || 0),
      practiceTotalScore: Number(route.query.practiceTotalScore || 0),
      questionNum: Number(route.query.questionNum || 0),
      folderIds: [],
      practiceEnum: {
        1: proxy.$t("practice.mode.daily"),
        2: proxy.$t("practice.mode.interest"),
        3: proxy.$t("practice.mode.simulation"),
        4: proxy.$t("practice.mode.self"),
      },
      dataInfo: {
        questions: [],
      },
      itemScore: 0,
      submitLoading: false,
      showSheet: false,
      downTime: {
        h: "00",
        m: "00",
        s: "00",
      },
    });

    if (route.query.folderIds) {
      state.folderIds = route.query.folderIds
        .split(",")
        .map((num) => Number(num));
    }

    const newPractice = ls.get("newPractice");
    ls.remove("newPractice");

    let isComplete = false;
    if (state.disabled) {
      isComplete = true;
    }

    const back = () => {
      router.go(-1);
    };

    const lsToken = ls.get(STORAGE_TOKEN_KEY);
    const endSubmit = () => {
      if (state.dataInfo.questions.length && !isComplete && !state.disabled) {
        fetch(
          process.env.VUE_APP_API_BASE_URL + `study/practice/simulate/save`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + lsToken,
            },
            body: JSON.stringify({
              submitId: state.dataInfo.submitId,
              submitList: state.dataInfo.questions,
            }),
            keepalive: true,
          }
        );
      }
    };

    window.addEventListener("beforeunload", function (e) {
      clearTimer();
      endSubmit();
    });

    onBeforeRouteLeave((to, from, next) => {
      if (!isComplete) {
        Modal.confirm({
          title: proxy.$t("practice.exit_confirmation"),
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            clearTimer();
            endSubmit();
            next();
          },
        });
      } else {
        next();
      }
    });

    const shuffle = (array) => {
      var m = array.length,
        t,
        i;
      while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }
      return array;
    };

    const initCorrectAnswers = (item) => {
      item.options.map((o, oIndex) => {
        if (item.questionType == 4 && item.strAnswer2[oIndex]) {
          if (item.strAnswer2[oIndex].split("$").includes(o.title)) {
            o.isCorrect = true;
          }
        } else {
          if (item.strAnswer2.includes(o.id)) {
            o.isCorrect = true;
          }
        }
      });
    };

    const getScore = () => {
      let score = state.practiceTotalScore / state.dataInfo.questions.length;
      if (score % 1 === 0) {
        score = Math.floor(
          state.practiceTotalScore / state.dataInfo.questions.length
        );
      } else {
        score = (
          state.practiceTotalScore / state.dataInfo.questions.length
        ).toFixed(1);
      }
      state.itemScore = score;
    };

    const getDetail = () => {
      let action = null;
      let params = {};
      if (state.disabled) {
        action = practiceDetail;
        params = { status: 0, submitId: state.submitId };
      } else if (state.isHistory || state.isReload) {
        if (state.practiceType == 3) {
          action = practiceHistory;
          params = { type: 3 };
        } else if (state.practiceType == 4) {
          action = practiceHistory;
          params = { type: 5 };
        }
      } else {
        if (state.practiceType == 3) {
          action = simulateQuestions;
          params = { categoryIds: state.folderIds };
        } else if (state.practiceType == 4) {
          action = simulateQuestions;
          params = {
            categoryIds: state.folderIds,
            practicePassScore: state.practicePassScore,
            practiceQuestions: state.practiceQuestions,
            practiceTimes: state.practiceTimes,
            practiceTotalScore: state.practiceTotalScore,
            questionNum: state.questionNum,
          };
        }
      }
      state.spinning = true;
      action(params).then((res) => {
        state.spinning = false;
        if (res.ret === 0) {
          state.dataInfo = res.data;
          state.dataInfo.questions = state.dataInfo.questions || [];
          if (state.isHistory || state.isReload) {
            state.practiceTotalScore = res.data.totalScore;
          }
          getScore();
          if (state.dataInfo.questions.length > 0) {
            state.dataInfo.practiceTimes = state.dataInfo.practiceTimes * 60;
            state.dataInfo.questions.map((item) => {
              item.strAnswer2 = JSON.parse(item.strAnswer);
              if (state.disabled) {
                if (item.questionType == 1 || item.questionType == 3) {
                  item.userAnswer = "";
                  item.options.forEach((opt) => {
                    if (opt.isRight) item.userAnswer = opt.id;
                  });
                } else if (item.questionType == 2) {
                  item.userAnswer = [];
                  item.options.forEach((opt) => {
                    if (opt.isRight) item.userAnswer.push(opt.id);
                  });
                }
              } else {
                if (state.isHistory || state.isReload) {
                  item.answers = item.answers || [];
                  if (item.questionType == 1 || item.questionType == 3) {
                    item.userAnswer = "";
                    item.options.forEach((opt) => {
                      if (opt.isRight) item.userAnswer = opt.id;
                    });
                  } else if (item.questionType == 2) {
                    item.userAnswer = [];
                    item.options.forEach((opt) => {
                      if (opt.isRight) item.userAnswer.push(opt.id);
                    });
                  }
                } else {
                  item.answers = [];
                }
              }
              item.options = item.options || [];
              if (item.questionType == 4) {
                item.titles = item.title.split("＿");
                if (!item.options.length) {
                  for (var i = 0; i < item.titles.length - 1; i++) {
                    item.options.push({ id: i, title: "", isRight: false });
                  }
                }
              } else {
                if (item.optionRandom == 1) {
                  item.options = shuffle(item.options);
                }
              }
              if (state.isHistory || state.isReload) {
                if (item.answers.length) {
                  item.isDoing = true;
                }
              }
              initCorrectAnswers(item);
            });
          }
          if (!state.disabled) {
            if (state.dataInfo.practiceTimes == 0) {
              submitMsg = proxy.$t("practice.time_end_submit");
              toResult(1);
            } else {
              formatDuring(state.dataInfo.practiceTimes * 1000);
              countDown(state.dataInfo.practiceTimes * 1000);
            }
          }
        } else {
          isComplete = true;
        }
      });
    };

    if (newPractice || state.isHistory || state.disabled) {
      getDetail();
    } else {
      Modal.confirm({
        title: proxy.$t("practice.do_you_want_to_continue_answering_question"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: proxy.$t("cm_yes"),
        onOk() {
          state.isReload = true;
          getDetail();
        },
        cancelText: proxy.$t("cm_no"),
        onCancel() {
          getDetail();
        },
      });
    }

    const formatDuring = (sec) => {
      let hours = parseInt((sec % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = parseInt((sec % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = (sec % (1000 * 60)) / 1000;
      state.downTime.h = hours < 10 ? "0" + hours : hours;
      state.downTime.m = minutes < 10 ? "0" + minutes : minutes;
      state.downTime.s = seconds < 10 ? "0" + seconds : seconds;
    };

    let autoTimer = null;
    let downTimer = null;
    let submitMsg = proxy.$t("LB_Teacher_SubSuccess");
    const countDown = (sec) => {
      autoTimer = setInterval(() => {
        toResult(2);
      }, 29 * 1000);
      downTimer = setInterval(() => {
        sec -= 1000;
        formatDuring(sec);
        if (sec === 0) {
          clearInterval(downTimer);
          submitMsg = proxy.$t("practice.time_end_submit");
          toResult(1);
        }
      }, 1000);
    };

    const grtUserAnswer = (item) => {
      setTimeout(() => {
        if ([1, 3].includes(item.questionType)) {
          if (item.userAnswer || item.userAnswer === 0) {
            item.isDoing = true;
            item.answers = [item.userAnswer];
          } else {
            item.isDoing = false;
          }
        } else if (item.questionType == 2) {
          if (item.userAnswer && item.userAnswer.length >= 2) {
            item.isDoing = true;
            item.answers = JSON.parse(JSON.stringify(item.userAnswer));
          } else {
            item.isDoing = false;
          }
        } else if (item.questionType == 4) {
          item.isDoing = false;
          item.userAnswer = [];
          item.options.map((arr) => {
            if (arr.title.trim() !== "") {
              item.isDoing = true;
              item.userAnswer.push(arr.id);
            }
          });
          item.answers = item.userAnswer;
        }
      }, 100);
    };

    const clearTimer = () => {
      if (downTimer) {
        clearInterval(downTimer);
        downTimer = null;
      }
      if (autoTimer) {
        clearInterval(autoTimer);
        autoTimer = null;
      }
    };

    const scrollTo = (id) => {
      state.showSheet = false;
      document.getElementById("que_" + id).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    };

    const toResult = (type) => {
      let method = simulateSubmit;
      if (type == 1) {
        method = simulateSubmit;
        state.submitLoading = true;
      } else {
        method = simulateSave;
      }
      let params = {
        submitId: state.dataInfo.submitId,
        submitList: state.dataInfo.questions,
      };
      method(params).then((res) => {
        if (type == 1) {
          state.submitLoading = false;
          if (res.ret == 0) {
            isComplete = true;
            proxy.$message.success(submitMsg);
            router.replace({
              path: "/mine/practice",
            });
          }
        }
      });
    };

    const toSubmit = () => {
      state.showSheet = false;
      let surplus = 0;
      state.dataInfo.questions.forEach((item) => {
        if (!item.isDoing) surplus++;
      });
      if (surplus) {
        Modal.confirm({
          title: proxy.$t("practice.unanswered_to_submit2", [surplus]),
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            toResult(1);
          },
        });
      } else {
        toResult(1);
      }
    };

    onBeforeUnmount(() => {
      clearTimer();
    });

    return {
      questionTypeEnum,
      letterOpts,
      ...toRefs(state),
      grtUserAnswer,
      scrollTo,
      toSubmit,
      back,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
}
.banner {
  width: 100%;
  height: 200px;
  background-image: url("~@/assets/image/practice/banner.png");
  background-size: 100% 100%;
  border-radius: 8px 8px 0 0;
}
.header {
  margin: 0 24px;
  padding: 24px 0;
  line-height: 26px;
  .mixinFlex(space-between; center);
  .crumbs {
    font-size: 14px;
    color: #999;
    span {
      color: @color-theme;
    }
  }
}
.content-wrap {
  min-height: 200px;
  margin: 0 24px;
  position: relative;
  .count-down {
    margin-bottom: 20px;
    font-size: 16px;
    color: #666666;
    span {
      color: #e82f2e;
    }
  }
  .question {
    border-radius: 4px;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 10px rgba(148, 148, 148, 0.15);
    &-top {
      .mixinFlex(space-between; center);
      line-height: 26px;
      padding: 16px;
      border-bottom: 1px solid #f4f4f4;
      h6 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .flag {
        .mixinImgWrap(24px; 24px);
        cursor: pointer;
      }
    }
    &-body {
      padding: 16px 16px 0;
    }
    &-tit {
      font-size: 16px;
      line-height: 26px;
      color: #333;
      margin-bottom: 24px;
      &.fill-in {
        margin-bottom: 0;
        padding-bottom: 16px;
      }
    }
    .opt-input {
      margin: 0 5px;
      border: none;
      border-bottom: 1px solid #202020;
      padding: 1px 4px;
      text-align: center;
      &.text {
        padding: 1px 16px;
      }
      &.right {
        border-color: #2ec45a;
      }
      &.error {
        border-color: #e82f2e;
      }
    }
    &-opt {
      .ant-radio-group,
      .ant-checkbox-group {
        width: 100%;
        .mixinFlex(space-between);
        flex-wrap: wrap;
      }
      .opt-checkbox,
      .opt-radio {
        .mixinFlex(flex-start; center);
        white-space: normal !important;
        width: 49%;
        margin-right: 0;
        ::v-deep(.ant-checkbox),
        ::v-deep(.ant-radio) {
          display: none;
          width: 0px;
          height: 0px;
          overflow: hidden;
        }
        ::v-deep(span.ant-radio + *),
        ::v-deep(.ant-checkbox + span) {
          padding-right: 0;
          padding-left: 0;
          width: 100%;
          height: 100%;
        }
        .opt-item {
          .mixinFlex(flex-start; flex-start);
          font-size: 16px;
          margin-bottom: 16px;
          border-radius: 4px;
          background: #fafcfe;
          border: 1px solid #ebebeb;
          padding: 12px;
          height: calc(100% - 16px);

          &.right {
            background-color: #eafeee;
            border-color: #2ec45a;
          }
          &.error {
            background-color: #feefef;
            border-color: #e82f2e;
          }
          ::v-deep(.opt-media-wrap) {
            margin-left: -40px;
          }
          .letter {
            width: 28px;
            height: 28px;
            .mixinFlex(center; center);
            margin-right: 12px;
            border: 1px solid #dddddd;
          }
          .info {
            width: calc(100% - 40px);
          }
          .text {
            color: #202020;
            line-height: 28px;
            max-width: calc(100% - 48px);
          }
        }
        &.ant-checkbox-wrapper-checked,
        &.ant-radio-wrapper-checked {
          .letter {
            border-color: #266fff;
            color: #fff;
            background-color: #266fff;
          }
          .text {
            color: #266fff;
          }
        }
      }
      .ant-radio-wrapper {
        .letter {
          border-radius: 50%;
        }
      }
      .ant-checkbox-wrapper {
        .letter {
          border-radius: 4px;
        }
      }
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
  }
  .question-btn {
    padding: 12px 0 32px;
    text-align: center;
  }
  .explain {
    padding-bottom: 8px;
    &-answer {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 26px;
      word-break: break-all;
      .mixinFlex();
      .text {
        margin-right: 48px;
      }
      .right-opt {
        padding-right: 8px;
        color: #22cf33;
      }
      .my-opt {
        padding-right: 8px;
        color: #266fff;
      }
    }
    p {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 26px;
      word-break: break-all;
    }
  }
  .answer-sheet {
    width: 80px;
    position: absolute;
    right: 16px;
    .btn {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 2px 10px rgba(148, 148, 148, 0.15);
      .mixinFlex(center; center);
      flex-direction: column;
      cursor: pointer;
      position: fixed;
      bottom: 150px;
      .img {
        .mixinImgWrap(28px; 28px);
      }
      span {
        font-size: 12px;
        line-height: 17px;
        color: #377dff;
        margin-top: 2px;
      }
    }
  }
}
.sheet {
  background-color: #fff;
  &-head {
    .mixinFlex(flex-start; center);
    margin-bottom: 20px;
    h5 {
      font-size: 16px;
      color: #333;
      line-height: 24px;
      margin-bottom: 0;
      font-weight: 600;
      margin-right: 24px;
    }
    .block {
      .mixinFlex(flex-start; center);
      margin-right: 24px;
      span {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        border-radius: 2px;
      }
      &.ed {
        span {
          border: 1px solid #447dff;
          background-color: #447dff;
        }
      }
      &.no {
        span {
          border: 1px solid #999999;
        }
      }
      &.flag {
        span {
          border: 1px solid #999999;
          position: relative;
          overflow: hidden;
          &:after {
            content: "";
            position: absolute;
            bottom: -10px;
            right: -10px;
            width: 20px;
            height: 20px;
            background-color: #f65464;
            transform: rotate(45deg);
          }
        }
      }
      &.right {
        span {
          border: 1px solid #22cf33;
          background-color: #22cf33;
        }
      }
      &.error {
        span {
          border: 1px solid #e82f2e;
          background-color: #e82f2e;
        }
      }
    }
  }
  &-number {
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    margin-bottom: 16px;
  }
  &-list {
    max-height: 500px;
    overflow-y: auto;
    .item {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 2px;
      border: 1px solid #999999;
      cursor: pointer;
      margin-right: 16px;
      margin-bottom: 16px;
      color: #999999;
      font-size: 18px;
      .mixinFlex(center; center);
      display: inline-flex;
      position: relative;
      &:nth-child(10n) {
        margin-right: 0;
      }
      &.checked {
        border-color: #447dff;
        background-color: #447dff;
        color: #fff;
      }
      &.marked {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: -6px;
          right: -6px;
          width: 12px;
          height: 12px;
          background-color: #f65464;
          transform: rotate(45deg);
        }
      }
      &.right {
        border-color: #22cf33;
        color: #22cf33;
      }
      &.error {
        border-color: #e82f2e;
        color: #e82f2e;
      }
    }
  }
  &-btn {
    padding: 8px 0 16px;
    text-align: center;
  }
}
</style>
